import { useState } from 'react';
import Axios from 'axios';
import { message } from 'antd';

import keys from 'utils/keys';
import endpoint from 'utils/endpoint';
import handleError from 'utils/handleError';
import { isEmpty, putValueInSuffixEndpoint } from 'helpers/utils';

export default function useSelectOptionsDB({ form, initialValues = {}, epVars = {} }) {
  const [loading, setLoading] = useState(false);
  const [selectOptionsDB, setSelectOptionsDB] = useState({}); // For field type 'lookup_select'

  const populateSelectOptionDB = async (field, initialTime = false, _ep) => {
    if (field.type !== keys.SELECT_TYPES[0]) return; // if not a lookup_select type
    let hide;
    try {
      setLoading(true);
      const values = {};

      const linked_field = field.linked_field_required;
      if (!initialTime && !linked_field && !_ep) return;

      const directValues = { ...epVars };
      if (linked_field && field.endpoint?.includes?.(`:${linked_field}`)) {
        let v = form.getFieldValue(linked_field);
        if (isEmpty(v) && initialTime) v = initialValues?.[linked_field];
        if (isEmpty(v)) return;
        directValues[linked_field] = v;
      }

      if (!initialTime) {
        hide = message.loading(`Loading options for field '${field.label}'`, 0);
      }

      values['directValues'] = directValues;
      let epSuffix = putValueInSuffixEndpoint(_ep || field.endpoint, values);
      let ep = endpoint.getLookupEndpoint(epSuffix);
      const res = await Axios.get(ep);
      console.log(`(LABEL) ${field.label} (TYPE) '${field.type}' response:`, res.data);
      const list = (Array.isArray(res.data?.list) ? res.data?.list : res.data) || [];
      setSelectOptionsDB(prevData => {
        return { ...prevData, [field.field]: list };
      });
    } catch (error) {
      handleError(error, true);
    } finally {
      setLoading(false);
      hide && hide();
    }
  };

  return [selectOptionsDB, { loading, populateSelectOptionDB }];
}
