const vars = {
  APP_NAME: 'Liberty',
  PRIMARY_COLOR: '#2c7be5',
  SECONDARY_COLOR: '#38444fd4',
  SKELETON_BACKGROUND: '#D3E4F4',
  PAGINATION_PAGE_SIZE_OPTIONS: [25, 100, 250, 500],
  DEBOUNCE_TIME: 500,
  STICKY_OFFSET_HEADER: 70,
  EMPTY_VALUE_MSG: `No data`,
  IS_LOCALHOST: window.location.host.includes('localhost'),
  CENTER_CLASS: 'd-flex justify-content-center align-items-center',
  CM_APP: {
    baseZIndex: 9,
    grayColor: '#00000033',
    greenColor: '#28a745',
    dangerColor: '#dc3545',
    warningColor: '#ffc107',
    lightBlueColor: '#238AE6',
    infoColor: '#17a2b8',
    shadowColor: '#f2f2f2',
    iconSize: 18,
    CM_APP_BASE_EP_KEY: 'CM_APP_BASE_EP_KEY',
    searchButtonId: 'CM_APP_SEARCH_BUTTON_ID',
    searchQuery: 'CM_APP_SEARCH_QUERY',
    localPrefix: `__local@`
  }
};

export default vars;
