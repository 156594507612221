import keys from './keys';
import { isEmpty } from 'helpers/utils';

export const getApiBaseUrl = () => {
  const appConfig = window.appConfig ?? {};
  const HOST = window.location.origin;
  const BASE_URL = appConfig.api_base_url || (appConfig.production ? HOST : `https://libertydev.blueboxonline.com`);
  // console.log({ BASE_URL });
  return BASE_URL;
};

export const API_VERSION = `v1`;
export const getApiEpPrefix = () => `${getApiBaseUrl()}/api/${API_VERSION}`;
export const getCmApiEpPrefix = () => `${getApiBaseUrl()}/cm-api/${API_VERSION}`;

export default {
  settings: `${getApiEpPrefix()}/app/settings`,
  login: `${getApiEpPrefix()}/users/login`,
  otp: `${getApiEpPrefix()}/users/otp`,
  forgotPassword: `${getApiEpPrefix()}/users/forgot-password`,
  resetPassword: `${getApiEpPrefix()}/users/reset-password`,
  logout: `${getApiEpPrefix()}/users/logout`,
  userProfile: `${getApiEpPrefix()}/users/profile`,
  authTokenEndpoint: `${getApiEpPrefix()}/oauth/token`,
  authRevokeEndpoint: `${getApiEpPrefix()}/oauth/revoke`,
  getEndpointWithSuffix: suffix => `${getApiEpPrefix()}${suffix}`,
  getCMEndpointWithSuffix: (suffix, viewId, ctId) =>
    `${getCmApiEpPrefix()}${suffix}${isEmpty(viewId) ? '' : `?${keys.FIELDS.contracts_views_id}=${viewId}`}${
      isEmpty(ctId) ? '' : `${viewId ? '&' : '?'}${keys.FIELDS.contract_types_id}=${ctId}`
    }`, // ctId = Contract Type ID
  getPageSchemaEndpoint: routeKey => `${getApiEpPrefix()}/pages/${routeKey}`,
  getDataModuleSchemaEndpoint: (mn, action, id) =>
    `${getApiEpPrefix()}/schemas/${mn}/${action}${id ? `?id=${id}` : ''}`,
  getModuleDataEndpoint: mn => `${getApiEpPrefix()}/module/${mn}`,
  getDropTableEndpoint: mn => `${getApiEpPrefix()}/module/${mn}/table`,
  getDropColumnEndpoint: mn => `${getApiEpPrefix()}/module/${mn}/columns`,
  getDataAddEndpoint: mn => `${getApiEpPrefix()}/module/${mn}`,
  getDataItemViewEndpoint: (mn, id) => `${getApiEpPrefix()}/module/${mn}/${id}`,
  getDataItemEditEndpoint: (mn, id) => `${getApiEpPrefix()}/module/${mn}/${id}`,
  getDataItemDeleteEndpoint: (mn, id) => `${getApiEpPrefix()}/module/${mn}/${id}`,
  getModuleSettingsEndpoint: mn => `${getApiEpPrefix()}/module/${mn}/settings`,
  getModuleSettingUpdateEndpoint: (mn, setting) => `${getApiEpPrefix()}/module/${mn}/settings/${setting}`,
  getDefaultDataEndpoint: mn => `${getApiEpPrefix()}/module/${mn}/table/default-data`,
  getNotificationClickEndpoint: id => `${getApiEpPrefix()}/users/notifications/${id}/read`,
  getMoreNotificationEndpoint: (offset = 5) => `${getApiEpPrefix()}/users/notifications?offset=${offset}`,
  fontAwesomeIcons: `${getApiEpPrefix()}/fontawesome-icon-list`,
  getLookupEndpoint: suffix => `${getApiEpPrefix()}${suffix}`,
  getBatchEndpointForSort: mn => `${getApiEpPrefix()}/module/${mn}/batch`,
  getDataItemTabViewEndpoint: (suffix, id) => `${getApiEpPrefix()}/${suffix.replace(':id', id)}`,
  getContractManageFieldEndpoint: viewId =>
    `${getApiEpPrefix()}/module/bb_ls_customer_contracts_views/${viewId}/manage-fields`
};
