import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useRef } from 'react';

function StagesInputField({ extraData }) {
  const _isMounted = useRef(false);
  const { stagesList, stages, setStages, submittingForm } = extraData || {};

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Row className="mb-1" justify="center" align="middle" gutter={[8, 8]}>
        {stagesList.map((item, index) => {
          return (
            <Col key={index} span={24}>
              <Checkbox
                checked={stages[item._id]}
                disabled={item.requiredYN === true || submittingForm}
                onChange={e => {
                  const val = e.target.checked;
                  setStages(prevData => ({ ...prevData, [item._id]: val }));
                  if (val) {
                    // if true
                    const updates = {};
                    item.linked_stages_ids.forEach(_id => {
                      updates[_id] = true;
                    });
                    setStages(prevData => ({ ...prevData, ...updates }));
                  }
                }}
              >
                {item.name}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default StagesInputField;
