import dotProp from 'dot-prop-immutable';
import { createSlice } from '@reduxjs/toolkit';

const CM_APP_STATE_BLUEPRINT = {
  fetching: true,
  doc: null, // Object with fields - author,completed,date,fields,manage_fields,manage_filters,manage_groups,menu,name,total_pages,viewer
  fieldAmendments: {},
  // Search Related
  searching: false,
  currentFocus: null,
  searchMatches: null,
  searchResult: null, // Store the 'viewer' property
  // Custom
  docIsProcessing: false, // Doc is still processing or not
  isViewMode: true, // For UI limitation (View/Management Mode)
  valueDisplayFilterVal: 'text', // Filter (Value/Display Mode) // Value = text & Display = html
  mode: '',
  dataKey: '', // It's the '_apikey' property from response
  isAmendmentMode: false,
  activeFieldInRuleBuilder: null,
  viewingField: null, // Currently open | So that only one field can open at a time
  viewId: '', // Store doc manager selected filter
  contractTypeId: '' // Store doc manager selected filter
};

const initialState = { ...CM_APP_STATE_BLUEPRINT };

/* Store CM App related everything */
const cmAppSlice = createSlice({
  name: 'cmApp',
  initialState,
  reducers: {
    updateCMAppState(state, { payload }) {
      return { ...state, ...payload };
    },
    setCMAppDocFields(state, { payload }) {
      return dotProp.set(state, `doc.fields`, payload);
    },
    setCMAppDocFieldRows(state, { payload }) {
      const { rows, groupId } = payload;
      // First level fields means groups. It's a bit confusing naming.
      return dotProp.set(state, `doc.fields`, groups => {
        return groups.map(group => {
          if (group.id === groupId) return { ...group, rows };
          return group;
        });
      });
    },
    resetCMAppState() {
      return { ...initialState };
    }
  }
});

export const { updateCMAppState, setCMAppDocFields, setCMAppDocFieldRows, resetCMAppState } = cmAppSlice.actions;

export default cmAppSlice.reducer;
