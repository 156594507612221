import Axios from 'axios';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, Empty, Form, Row, message } from 'antd';

import keys from 'utils/keys';
import endpoint from 'utils/endpoint';
import handleError from 'utils/handleError';
import { CMAppTagItem } from 'components/CMApp/CMAppUtils';
import { getErrorAlert, getPopupContainer, isEmpty, renderLoading } from 'helpers/utils';
import { constructEndpointWithRecord, getModuleNameFromEndpoint } from './formHelpers';

function TagsField(props) {
  const [form] = Form.useForm();
  const _isMounted = useRef(false);
  let { id: docId, routeKey } = useParams();
  const { fieldData, record, idFieldKey } = props;
  const contract_id =
    record[keys.FIELDS.contract_id] ?? (routeKey === keys.ROUTE_KEY.DOCUMENT ? docId : record[idFieldKey]);
  const baseEpSuffix = fieldData.endpoint;
  const moduleName = getModuleNameFromEndpoint(baseEpSuffix) ?? keys.MODULES.CUSTOMER_CONTRACT_TAGS;
  const finalBaseEpSuffix = constructEndpointWithRecord(baseEpSuffix, { id: contract_id });
  const customerId = useSelector(state => state.auth?.user?.customer?._id);

  const [saving, setSaving] = useState(false);
  const [tagsRes, setTagsRes] = useState(null);
  const [viewMode, setViewMode] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const tags = tagsRes?.list;
  const resetResults = () => _isMounted.current && setSearchResults([]);

  const handleTagDelete = async tagId => {
    try {
      _isMounted.current && setDeletingId(tagId);
      const ep = endpoint.getDataItemDeleteEndpoint(moduleName, tagId);
      const req = await Axios.delete(ep);
      const res = req.data || {};
      console.log('Tag delete response:', res);
      setTagsRes(prevRes => ({ ...prevRes, list: prevRes.list.filter(x => x.value !== tagId) }));
    } catch (error) {
      handleError(error, true);
    } finally {
      _isMounted.current && setDeletingId('');
    }
  };

  const getData = async () => {
    try {
      _isMounted.current && setFetching(true);
      const ep = endpoint.getEndpointWithSuffix(finalBaseEpSuffix);
      const req = await Axios.get(ep);
      const res = req.data || {};
      console.log(`Current tags response: `, res);
      _isMounted.current && setTagsRes(res);
    } catch (error) {
      handleError(error, true);
    } finally {
      _isMounted.current && setFetching(false);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    getData();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const showMsg = (msg = '', type = 'info') => {
    if (!msg) return;
    message[type]({
      content: msg,
      duration: 3,
      getPopupContainer: getPopupContainer
    });
  };

  const onFormSubmit = async (values, isNew = true) => {
    const { tag: newTagText, ...rest } = values;
    let tagInfo = rest;

    // Check already added
    if (tags.some(x => x.text === newTagText)) {
      return showMsg('Tag already exist!');
    }

    try {
      _isMounted.current && setSaving(true);
      if (isNew) {
        // Is totally new one | Add in customer tag
        const { data: newTagData } = await Axios.post(endpoint.getDataAddEndpoint(keys.MODULES.CUSTOMER_TAGS), {
          customerISbb_ls_customersID: customerId,
          name: newTagText
        });
        tagInfo = newTagData;
        console.log('Adding a new customer tag response:', newTagData);
      }

      // Add in customer contract tag
      const req = await Axios.post(endpoint.getDataAddEndpoint(moduleName), {
        contractISbb_ls_customer_contractsID: contract_id,
        tagISbb_ls_customer_tagsID: tagInfo._id
      });
      const res = req.data || {};
      console.log('Adding a new customer contract tag response:', res);
      setTagsRes(prevRes => ({
        ...prevRes,
        list: [
          ...(prevRes.list ?? []),
          { text: res.tagISbb_ls_customer_tagsID_display, value: res._id, color: res.color }
        ]
      }));
      form.resetFields();
    } catch (error) {
      handleError(error, true);
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const onSearch = async value => {
    if (!value) return resetResults();
    try {
      _isMounted.current && setSearching(true);
      const ep = endpoint.getEndpointWithSuffix(
        `/module/bb_ls_customer_tags/search?type=input&module=bb_ls_customer_tags&field=_id&query=${value}`
      );
      const req = await Axios.get(ep);
      const res = req.data || {};
      console.log('Search tags response:', res);
      _isMounted.current && setSearchResults(res?.list ?? []);
    } catch (error) {
      handleError(error, true);
    } finally {
      _isMounted.current && setSearching(false);
    }
  };

  const renderMain = () => {
    if (isEmpty(tagsRes)) return getErrorAlert({ onRetry: getData });
    if (isEmpty(tags)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

    return (
      <>
        <Row justify="space-between" className="w-100 mb-2">
          <Col flex={1} />
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                resetResults();
                _isMounted.current && setViewMode(prevMode => !prevMode);
              }}
              icon={viewMode ? <PlusCircleOutlined /> : <EyeOutlined />}
            >
              {viewMode ? 'Add Tag' : 'View Tags'}
            </Button>
          </Col>
        </Row>

        <Row className="w-100" gutter={[1, 6]}>
          {tags.map(tag => {
            const tagId = tag.value;
            const color = tag.color ?? tag?.extra_data?.color;
            return (
              <Col key={tagId}>
                <CMAppTagItem
                  text={tag.text}
                  {...(color ? { color } : {})}
                  className="badge"
                  processing={deletingId === tagId}
                  closable={deletingId !== tagId}
                  onClose={e => {
                    e.preventDefault();
                    handleTagDelete(tagId);
                  }}
                />
              </Col>
            );
          })}
        </Row>

        {!viewMode && (
          <Form
            form={form}
            preserve={false}
            layout="vertical"
            scrollToFirstError
            onFinish={onFormSubmit}
            onFinishFailed={() => showMsg('Please fix form errors!', 'warn')}
            disabled={saving}
            className="w-100 mt-2"
            name={`app-tag-form`}
          >
            <Form.Item name="tag" rules={[{ required: true, whitespace: true, message: `Tag is required!` }]}>
              <AutoComplete
                autoFocus
                required
                allowClear
                loading={searching || saving}
                filterOption={true}
                placeholder="Add new tag..."
                getPopupContainer={getPopupContainer}
                onSelect={(tag, option) => onFormSubmit({ tag, ...option }, false)}
                onSearch={debounce(onSearch, 500)}
                onClear={() => resetResults()}
                options={searchResults.map(x => ({ ...x, _id: x.value, value: x.text, key: x.value }))}
              />
            </Form.Item>
          </Form>
        )}
      </>
    );
  };

  return (
    <>
      <div className="w-100 my-2 p-2 border rounded-3">
        {fetching ? renderLoading({ size: 'small', tip: 'Loading Tags...', className: 'my-3' }) : renderMain()}
      </div>
    </>
  );
}

export default TagsField;

export const TagItemView = ({ tag }) => {
  if (isEmpty(tag)) return null;
  const color = tag.color ?? tag?.extra_data?.color;
  return <CMAppTagItem text={tag.name ?? ''} className="badge mt-2" {...(color ? { color } : {})} />;
};
