import { useState } from 'react';
import Axios from 'axios';

import endpoint from 'utils/endpoint';
import handleError from 'utils/handleError';

export default function useLookupSelectDB({ initDB } = {}) {
  const [allLookupFields, setAllLookupFields] = useState(initDB || {});

  const fetchLookupData = async (fieldKey, query, ep) => {
    const lookupFieldInfo = allLookupFields[fieldKey] || {};
    lookupFieldInfo.lookupQuery = query;
    setAllLookupFields({ ...allLookupFields, [fieldKey]: lookupFieldInfo });
    if (!query || !ep) return;
    lookupFieldInfo.lookupLoading = true;
    setAllLookupFields({ ...allLookupFields, [fieldKey]: lookupFieldInfo });
    let _endpoint = endpoint.getLookupEndpoint(ep + `&query=${query}`);

    try {
      const res = await Axios.get(_endpoint);
      const resData = res.data;
      console.log(`Lookup response:`, resData);
      lookupFieldInfo.lookupData = resData.list;
      lookupFieldInfo.lookupLoading = false;
      if (resData.module) {
        lookupFieldInfo.module = resData.module;
      }
      setAllLookupFields({ ...allLookupFields, [fieldKey]: lookupFieldInfo });
    } catch (error) {
      handleError(error, true);
      lookupFieldInfo.lookupLoading = false;
      setAllLookupFields({ ...allLookupFields, [fieldKey]: lookupFieldInfo });
    }
  };

  const setLookupData = (lookupFieldKey, data = [], concat = true) => {
    if (!lookupFieldKey) return;
    const current = allLookupFields[lookupFieldKey];
    const options = current?.lookupData ?? [];
    current.lookupData = concat ? [...data, ...options] : data;
    current.lookupLoading = false;
    setAllLookupFields({ ...allLookupFields, [lookupFieldKey]: { ...current } });
  };

  return [allLookupFields, { fetchLookupData, setLookupData }];
}
