import React from 'react';
import { TiInfo } from 'react-icons/ti';
import { Tooltip, message, Tag } from 'antd';
import { AiOutlineDrag } from 'react-icons/ai';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { SyncOutlined, TagOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, SortableHandle } from 'react-sortable-hoc';
import { DownCircleOutlined, LoadingOutlined, UpCircleOutlined } from '@ant-design/icons';

import vars from 'utils/vars';
import { isEmpty } from 'helpers/utils';

const { iconSize, grayColor, infoColor, greenColor, dangerColor, warningColor } = vars.CM_APP;

const cIconStyle = {
  fontSize: iconSize - 1,
  cursor: 'pointer',
  color: grayColor
};
export const getCollapseIcon = (open, getPopupContainer, type = 'Group') => {
  return open ? (
    <Tooltip title={`Close ${type}`} getPopupContainer={getPopupContainer}>
      <DownCircleOutlined style={cIconStyle} />
    </Tooltip>
  ) : (
    <Tooltip title={`Open ${type}`} getPopupContainer={getPopupContainer}>
      <UpCircleOutlined style={cIconStyle} />
    </Tooltip>
  );
};

export const getFieldStatusIcon = props => {
  const { autoApproved, approved, validated, empty, onClick, getPopupContainer, loading } = props;

  if (loading) {
    return <LoadingOutlined style={{ fontSize: iconSize - 3, color: vars.PRIMARY_COLOR }} />;
  }

  const cProps = { onClick, className: 'cursor-pointer' };
  let msg = `Validation Failed`;
  let icon = <TiInfo size={iconSize} color={warningColor} {...cProps} />;

  if (autoApproved) {
    msg = `Auto-approved Field`;
    icon = <MdCheckCircle size={iconSize} color={infoColor} {...cProps} />;
  } else if (approved) {
    msg = `Approved Field`;
    icon = <MdCheckCircle size={iconSize} color={greenColor} {...cProps} />;
  } else if (empty) {
    msg = `Field Empty`;
    icon = <IoMdCloseCircle size={iconSize} color={dangerColor} {...cProps} />;
  } else if (validated) {
    msg = `Un-approved Field`;
    icon = <MdCheckCircle size={iconSize} color={grayColor} {...cProps} />;
  }

  return (
    <Tooltip title={msg} placement="right" getPopupContainer={getPopupContainer}>
      {icon}
    </Tooltip>
  );
};

export const CMAppSortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});
export const CMAppSortableItem = sortableElement(({ children }) => {
  return <div>{children}</div>;
});
export const CMAppDragHandle = SortableHandle(({ getPopupContainer, placement, color = grayColor }) => (
  <Tooltip title="Click and drag to reorder" getPopupContainer={getPopupContainer} placement={placement}>
    <AiOutlineDrag
      // title="Click and drag to reorder"
      size={iconSize}
      color={color}
      style={{ cursor: 'grab' }}
    />
  </Tooltip>
));

export const startCMAppSearch = (_query, _fieldId) => {
  const query = _query ?? '';
  const fieldId = _fieldId ?? '';
  if (isEmpty(query) && isEmpty(fieldId)) return;

  localStorage.setItem(vars.CM_APP.searchQuery, JSON.stringify({ query, fieldId }));
  const searchButton = document.getElementById(vars.CM_APP.searchButtonId);
  searchButton && searchButton.click();
};

export const toggleForm = (
  ref,
  flag = false, // true = Disable | false = Enable
  selector = '.cm-app-search-input'
) => {
  let elements = ref.current.elements;
  for (let element of elements) {
    element.readOnly = flag ? true : false;
  }
  ref.current.querySelector(selector).style.opacity = flag ? 0.5 : 1;
};

export const getCMAppEP = (suffix = '') => {
  const baseEP = localStorage.getItem(vars.CM_APP.CM_APP_BASE_EP_KEY);
  if (!baseEP) {
    console.error("CM App base endpoint can't be empty!");
    return message.error('Something went wrong.');
  }

  const [bEndpoint, bQuery] = baseEP.split('?');
  const validQueryBase = bQuery ? bQuery.trim() : '';
  if (suffix) {
    if (!suffix.startsWith('/')) {
      suffix = `/${suffix}`;
    }
  }
  const and = validQueryBase ? (suffix?.includes('?') ? '&' : '?') : '';

  return bEndpoint.trim() + suffix + and + validQueryBase;
};

export const fieldTabs = [
  { label: `Value`, key: 'text' },
  { label: `Display`, key: 'html' }
];

export const CMAppTagItem = props => {
  const { text, processing, ...rest } = props;
  return (
    <Tag
      color="#738194"
      className="fw-semi-bold d-flex align-items-center"
      icon={processing ? <SyncOutlined spin /> : <TagOutlined />}
      {...rest}
    >
      {text}
    </Tag>
  );
};
