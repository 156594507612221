import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogoDefault from 'assets/img/illustrations/falcon.png';

const Logo = ({ at, width, className, src, ...rest }) => {
  const settings = useSelector(state => state.settings);
  const { splash_logoISfile, title } = settings;
  const logo = src || splash_logoISfile?.uri || LogoDefault;

  const cProps = {};
  const logoStyles = {};
  if (at === 'navbar-vertical') {
    logoStyles['maxHeight'] = 55;
    logoStyles['maxWidth'] = 110;
  }
  if (width) cProps.width = width;

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-2': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img src={logo} alt={title || 'Logo'} style={logoStyles} {...cProps} />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  className: PropTypes.string,
  textClass: PropTypes.string,
  src: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
