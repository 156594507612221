const keys = {
  // Key
  TINY_MCE_KEY: 'rv4jgcr6735kdsrmbi1wtk69j7y897ai0us75h9f80n7960e',
  MAPBOX_TOKEN: `pk.eyJ1IjoicGF1bGRldiIsImEiOiJjam9uN3NjaWExNTVvM3FxYXd3dW9sZXRzIn0.QLkSSbf_58VEOHePM_qY2g`,
  // For localStorage
  AUTH_TOKEN: 'AUTH_TOKEN', // JSON Web Token
  REDIRECT: 'REDIRECT',
  REDIRECT_AFTER_LOGIN: 'REDIRECT_AFTER_LOGIN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  SPLASH_LOGO: 'SPLASH_LOGO',
  OTP_METHOD_LABEL: 'otp_method_label',
  DEFAULT_HELP_TAB: 'DEFAULT_HELP_TAB',
  MODULE_LINK_VAL_FIELD_KEY: `module_link_field_value`,
  VIEW_ROUTE_KEY: `view_route`,
  // Element ID
  APP_MAIN_BODY_CONTENT_ID: 'app-main-body-content',
  MAIN_FILTER_BTN_ID: 'main-filter-btn',
  CUSTOMER_PROJECT_TAG_ADD_MODAL_BTN: 'customer-project-tag-add-modal-btn',
  // Element Attributes
  DATA_FIELD_ATTRIBUTE: `data-field`,
  // Others
  VISUAL_REQUIRED: `__visual_required`,
  REFETCH_SELECT_OPTION_PREFIX: `refetchSelectOptionsForField___`,
  DATA_VIEW_LIST_ACTION: 'viewlist',
  DATA_VIEW_ACTION: 'view',
  DATA_ADD_ACTION: 'add',
  DATA_EDIT_ACTION: 'edit',
  EDIT_PERMISSIONS_KEY: 'edit',
  DELETE_PERMISSIONS_KEY: 'delete',
  ADD_PERMISSIONS_KEY: 'add',
  PROFILE_IMAGE_FIELD_KEY: 'imageFILE',
  FA_FIELD_TYPE: 'fontawesome-icon-list',
  SELECT_TYPES: ['lookup_select', 'select'],
  ADD_FORM_SUBMIT_BUTTON: 'ADD_FORM_SUBMIT_BUTTON',
  LOAD_TEMPLATE: 'LOAD_TEMPLATE',
  DATA_PROFILE_ACTION: 'profile',
  DATE_FIELD_KEY: 'date',
  DATE_TIME_FIELD_KEY: 'datetime',
  RICHTEXT_TYPES: ['text-parse-html', 'textarea-rich'],
  CM_APP: {
    MODE: { CONTRACT: 'contract', AMENDMENT: 'amendment' }
  },
  ROUTE_KEY: {
    ADMIN: 'admin',
    REPORTS: 'reports',
    PROJECTS: 'projects',
    DOCUMENT: 'document',
    ADVANCED_REPORT: 'advanced-report',
    GRAPH_REPORT: 'graph-report',
    DASHBOARD: 'dashboard'
  },
  TEMPLATES: {
    help: 'help',
    iframe: 'iframe',
    doc_library: 'doc_library',
    project_library: 'project_library',
    quick_start: 'quick_start',
    document: 'document',
    project: 'project',
    doc_list: 'doc_library_list',
    project_list: 'project_library_list',
    graph_regional_spread: 'graph_regional_spread',
    dashboard: 'dashboard'
  },
  FIELDS: {
    project_id: 'projectISbb_ls_customer_projectsID',
    contract_id: 'contractISbb_ls_customer_contractsID',
    contract_types_id: 'contract_typeISbb_ls_contract_typesID',
    contracts_views_id: 'viewISbb_ls_customer_contracts_viewsID',

    customer_tag_id: 'tagISbb_ls_customer_tagsID'
  },
  MODULES: {
    STRIPE: ``,
    CUSTOMER_TAGS: 'bb_ls_customer_tags',
    CUSTOMER_CONTRACT_TAGS: 'bb_ls_customer_contracts_tags'
  },
  graphTypeValues: ['bar', 'pie'],
  FIELD_TYPE: {
    LOOKUP: 'lookup',
    SELECT_TREE: 'select_tree'
  },
  FORM_TYPE: {
    ADD: 'add',
    EDIT: 'edit',
    INLINE_ADD: 'inline-add',
    INLINE_EDIT: 'inline-edit'
  },
  STAGES_REF: {
    SETUP: `setup`,
    FIELDS: `fields`,
    UPLOAD: `upload`,
    EXTRACT_APPROVE: `extract_approve`,
    REVIEW: `review`,
    REPORTING: `reporting`,
    SHARE: `share`,
    SIGNOFF: `signoff`
  }
};

export default keys;
